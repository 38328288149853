












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Promoter from '@/models/Promoter';
import { promoterStoreActions } from '@/store/promoter.store';
import PromoterStatistics from '@/models/PromoterStatistics';
import Printd from 'printd';

const PromoterStore = namespace('promoter');

@Component
export default class PromoterStatisticsListComponent extends Vue {
    @Prop({ required: true })
    private promoter!: Promoter | null;

    @PromoterStore.Action(promoterStoreActions.STATISTICS)
    public getStatistics!: (id: string) => Promise<PromoterStatistics>;

    /**
     * PrintD Instance to print a given element
     */
    private printer: Printd = new Printd();

    private printStyle: string = `
    .headline {
      font-size: 24px;
      font-weight: 700;
      font-family: "Calibri", "Arial", "sans-serif";
    }

    .colorized {
        color: #E97C38;
    }

    .font-style {
        font-family: "Calibri", "Arial", "sans-serif";
    }

    h3 {
       margin-bottom: 4px;
    }

    .font-weight-bold {
       font-weight: 700;
    }

    .hide-on-print {
        display: none;
    }
  `;

    public isLoading: boolean = false;
    public statistics: PromoterStatistics | null = null;

    public async created() {
        this.isLoading = true;
        try {
            this.statistics = await this.getStatistics(this.promoter!.id);
        } finally {
            this.isLoading = false;
        }
    }

    private get hasStatistics(): boolean {
        return !!this.statistics && (this.statistics.drivers.length > 0 || this.statistics.companies.length > 0);
    }

    private printStatistics() {
        const el = document.getElementById('print-format');
        if (!el) return;
        this.printer.print(el!, [this.printStyle]);
    }

    public dismiss(reloadData: boolean = false) {
        this.$emit('closeDialog', reloadData);
    }
}

